<template>
  <UiActionBar
    v-model="modelValue"
    title="1 lead assigned"
    secondary-button-text="Close"
    :loading="loading"
    @cancel="emits('cancel')"
  >
    <template #primaryButton>
      <UiButtonBase id="popup_primary" size="big" class="w-auto" :disabled="loading" @click="moveToSales">
        <UiIcon name="operations-forward" />
        Move to Pipeline
      </UiButtonBase>
    </template>
  </UiActionBar>
</template>

<script setup lang="ts">
import { STAGES } from '~/constants'
import { useUiStore } from '~/store/ui'
import type { Lead, Pipeline } from '~/types'

const modelValue = defineModel<boolean>()

const emits = defineEmits(['input', 'cancel'])

const uiStore = useUiStore()

type Props = {
  lead: Lead
  salesPipeline: Pipeline
}
const props = defineProps<Props>()

const loading = ref(false)

const moveToSales = async () => {
  const newStage = props.salesPipeline.stages.find((s) => s.code === STAGES.NEW)
  if (newStage) {
    loading.value = true
    try {
      await useUpdateLead(props.lead.id, {
        pipeline_id: props.salesPipeline.id,
        pipeline_stage_id: newStage.id,
        pipeline_step_id: null,
      })
      emits('input')
      modelValue.value = false
      uiStore.showSnackBanner('Success', 'success')
    } catch (error: any) {
      uiStore.showSnackBanner(error.message, 'error')
    } finally {
      loading.value = false
    }
  }
}
</script>

<style scoped></style>
